import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest, HttpErrorResponse, HttpEventType } from '@angular/common/http';
import { SharedService } from 'src/app/shared/services/shared.service';
import { timer } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { SessionStorageService } from "src/app/components/surveys/services/session-storage.service";
import { SurveyService } from "src/app/components/surveys/services/survey.service";

@Injectable({
  providedIn: 'root'
})

export class DataTrackingService {

  private serviceUrl = this.sharedService.configuration.baseUrl;
  recordCount: number;

  constructor(private http: HttpClient, private sharedService: SharedService,
    private sessionStorageService: SessionStorageService, private surveyService: SurveyService,) {
    this.recordCount = 0;
  }

  getFile(filterObj) {
    let serviceUrl = this.sharedService.configuration.baseUrl;
    return this.http.post(this.serviceUrl + 'api/DataTracking/GetDataTrackingErrors', filterObj)
      .toPromise()
      .then((res: any) => res)
      .then(data => {
        return data;
      });
  }

  getDataForGrid(filterObj) {
    let serviceUrl = this.sharedService.configuration.baseUrl;
    /* let timer$ = timer(1, 60000)
      .pipe(switchMap(() => {
      }));
      return new Promise((resolve, reject) => {
        timer$.subscribe(res => resolve(res),
        err => reject(err));
      }); */
    // return timer(1, 15*60000)
      // .pipe(switchMap(() => {
        return this.http.post(this.serviceUrl + 'api/DataTracking/GetDataTrackingErrorsForGrid', filterObj)
      // }));
  }

  getEventIdDetails(eventId) {
    let serviceUrl = this.sharedService.configuration.baseUrl;
    return this.http.get(this.serviceUrl + 'api/DataTracking/GetDataTrackingErrorsDetails/' + eventId)
      .toPromise()
      .then((res: any) => res)
      .then(data => {
        return data;
      });
  }

  lockRecords(eventObject) {
    let serviceUrl = this.sharedService.configuration.baseUrl;
    return this.http.post(this.serviceUrl + 'api/DataTracking/LockRecord', eventObject)
      .toPromise()
      .then((res: any) => res)
      .then(data => {
        return data;
      });
  }

  UnlockRecord(unlockObj) {
    let unlockRecordJson = {
      "EventIds": unlockObj
    }
    let serviceUrl = this.sharedService.configuration.baseUrl;
    return this.http.post(this.serviceUrl + 'api/DataTracking/UnlockRecord', unlockRecordJson)
    .toPromise()
      .then((res: any) => res)
      .then(data => {
        return data;
      });
  }

  updateColumnId(postObject) {
    let serviceUrl = this.sharedService.configuration.baseUrl;
    return this.http.post(this.serviceUrl + 'api/DataTracking/Update', postObject)
      .toPromise()
      .then((res: any) => res)
      .then(data => {
        return data;
      });
  }

  updateEventId(recordData) {
    let serviceUrl = this.sharedService.configuration.baseUrl;
    return this.http.post(this.serviceUrl + 'api/DataTracking/UpdateByID ', recordData)
      .toPromise()
      .then((res: any) => res)
      .then(data => {
        return data;
      });
  }

  exportDataToExcel(filterObj) {
    return this.http.post(this.serviceUrl + 'api/DataTracking/ExportToExcel', filterObj, { responseType: 'blob' })
      .toPromise()
      .then((res: any) => res)
      .then(data => {
        return data;
      });
  }

  getDataFilterMenu(navFilter) {
    //let serviceUrl = environment.baseUrl;  this.serviceUrl + 'api/DataTracking/GetErrorCategoryDetails', filterObj
    return this.http.post(this.serviceUrl + 'api/DataTracking/GetErrorCategoryDetails', navFilter)
      .toPromise()
      .then((res: any) => res)
      .then(data => {
        return data;
      });

  }

  uploadFile(file) {
    let serviceUrl = this.sharedService.configuration.baseUrl + 'api/DataTracking/UploadFileForProcessing';

    return this.http.post(serviceUrl, file)
      .toPromise()
      .then((res: any) => res)
      .then(data => {
        return data;
      });
  }

  deleteDataTrack(questionObj) {
    return this.http.post(this.serviceUrl + 'api/DataTracking/DeleteEventId', questionObj)
      .toPromise()
      .then((res: any) => res)
      .then(data => {
        return data;
      });
  }

  getDropdownList(dataobj) {
    return this.http.post(this.serviceUrl + 'api/DataTracking/GetDropDownValues', dataobj).
      toPromise()
      .then((res: any) => res)
      .then(data => {
        return data;
      })
  }

  getModifiedColumn() {

    return this.http.get(this.serviceUrl + 'api/DataTracking/GetDataTrackingModifycolumnDetails/Datatracking').
    toPromise()
    .then((res: any) => res)
    .then(data => {
      return data;
    }) 

  }

  saveModifiedColumn(selectedColumns){
    
    const data =  JSON.stringify (selectedColumns);
    return this.http.post(this.serviceUrl + 'api/DataTracking/SaveDataTrackingModifyColumnfilter', {Gridfilter: data,screen : 'Datatracking' }).
    toPromise()
    .then((res: any) => res)
    .then(data => {
      return data;
    })
  }

  autoCorrectRecords(reqBody) {
    return this.http.post(this.serviceUrl + 'api/DataTracking/CorrectMarketSubSectorErrors', reqBody);
  }
}
