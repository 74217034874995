import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { SharedService } from 'src/app/shared/services/shared.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CtlsCategoryMasterService {
  private serviceUrl = environment.baseUrl;
  filterObj = {
    StartDate: true,
    EndDate: true,
    PeriodType: true,
    BACode: true,
    FAMCode: true,
    DivCode: true,
    BLCode: false,
    Country: true,
    BrandName: true,
    SalesEngineer: false,
    Touchpoint: true,
    Influencer: false,
    ServiceTechnician: false,
    Continent: true,
    Region: true,
    Zone: true,
    Branch: true,
    PinnedField: true,
    ActionType: true,
    surveySubCategoryCode: '',
    screenCode: 'CTLS_CATEGORY_MASTER', //SELECTED SCREEN
    sessionStorageName: 'CTLS_CATEGORY_MASTER',
    savedFilterSessionStorageName: 'CTLS_CATEGORY_MASTER_SAVED_FILTER',
    CustomerName: false, //11
    CustomerAccountNumber: false,
    Distributors: false,
    DistributorSegment: false,
    DistributorAccountNumber: false,
    showDateFilter: true,
    filterObjForGrid: [
      'Status',
      'IsDeleteEnable'
    ],
  };



  constructor(private http: HttpClient, private sharedService: SharedService) {
  }

  /**
    * Delete Task from Task Listing page
    * @param CTLSTaskListID: array of taskIds
    * @returns: Observable
    */
  DeleteCategory(cTLScategoryID): Observable<any> {
    return this.http.put<any>(
      `${this.serviceUrl}api/Master/DeleteCategory`,
      cTLScategoryID
    );
  }

  /**Service for load grid data in ctls configuration
   * @param filterObj
   * @param value
   */

  getCategoryMaster(filterObj): Observable<any> {
    return this.http.post<any>(
      `${this.serviceUrl}api/Master/GetAllCategory`,
      filterObj
    );
    // return of(mockData);
  }

  /**Service for save  modified columns
   * @param dataObj
   */
  SaveModifyColumns(dataObj): Observable<any> {
    return this.http.post<any>(
      `${this.serviceUrl}api/GridColumn/SaveModifyColumn`,
      dataObj
    );
  }

  /**Service for get  modified columns
   * @param dataObj
   */
  GetModifyColumns(dataObj) {
    return this.http.get<any>(
      `${this.serviceUrl}api/GridColumn/GetModifyColumn`,
      {
        params: {
          GridName: dataObj.GridName,
          SubCategoryCode: dataObj.SubCategoryCode,
        },
      }
    );
  }


  /**Service for saving status change
   * @param dataObj
   */
  saveStatus(dataObj): Observable<any> {
    return this.http.post<any>(
      `${this.serviceUrl}api/Master/EnableCategory?CategoryID=${dataObj.CategoryID}&IsEnable=${dataObj.IsEnable}`,
      null
    );
  }
  /**
 * Save Category from Category Listing page
 * @param saveObj: save Category
 * @returns: Observable
 */
  saveCategory(saveObj): Observable<any> {
    return this.http.post<any>(
      `${this.serviceUrl}api/Master/SaveCategory`,
      saveObj
    );
  }
}
